import './RightText2.css';

function RightText2() {


  return (
    <div className="RightText2">
 <div className='text'>
          <div className='title'>What People Are Saying</div>
          <div className='subtitle'>Don't just take our word for it. Here's what real people are saying about Toggle on Twitter.</div>
        </div>
        <img alt="img" src={'https://content-management-files.canva.com/9f8d2d84-54ac-4f76-b8f2-19fbe6775730/benefits-print-in-desktop.jpg'}/>
     
    </div>
  );
}

export default RightText2;

import './Nav.css';

function Nav() {


    return (
        <div className="Nav">
            <div className='Logo'>Toggle</div>
        </div>
    );
}

export default Nav;
